/* Global styles */
@import 'normalize';

:root {
  --app-ui-font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

/* CSS reset */
:where(:root) {
  box-sizing: border-box;
}

:where(*), ::before, ::after {
  box-sizing: inherit;
}

:where(body) {
  margin: 0;
  line-height: 1.5;
}

:where(p, ol, ul, dl, h1, h2, h3, h4, h5, h6, pre, hr, blockquote, figure) {
  margin: 0;
}

:where(ol, ul) {
  padding: 0;
  list-style: none;
}

:where(h1, h2, h3, h4, h5, h6) {
  font-size: inherit;
  font-weight: inherit;
}

:where(a) {
  color: inherit;
  text-decoration: inherit;
}

:where(img) {
  display: block;
}

:where(table) {
  border-collapse: collapse;
}
